import Dexie, { Table } from 'dexie';

export interface INameReference {
    id: string;
    referenceId: string;
    type: string;
    name: string;
    count: number;
    projectId: string;
    lastModified: string;
    version?: number | string;
}

export interface ITableSyncStatus {
    id: string;
    customerId: string;
    lastModified: string;
    tableName: string;
    version: number;
}

export class DfmDB extends Dexie {
    nameReference: Table<INameReference, string>;
    tableSyncStatus: Table<ITableSyncStatus, string>;

    constructor() {
        super('dfmDB');
        this.version(2).stores({
            nameReference: '++id, [projectId+type+name],[name+referenceId]',
            tableSyncStatus: '++id, [customerId+tableName]'
        });
    }
}

export const db = new DfmDB();