import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ExternalService } from 'angular4-hal';
import { EditObjectComponent } from 'core/components';
import { MaxTextLengthCategory } from 'core/components';
import { EMAIL_PATTERN } from 'core/constants';
import { AuthenticatedUser, ChangePasswordRequest } from 'core/models';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangePasswordFormState, User, UserType } from 'user-management/models';
import { IDecisionFirstState } from 'user-management/store/reducers';
import { WIDTH_PICTURE_PREVIEW } from './edit-user.const';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'user-management-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent extends EditObjectComponent<User> implements OnInit, OnDestroy {
  MaxTextLengthCategory = MaxTextLengthCategory;
  WIDTH_PICTURE_PREVIEW = WIDTH_PICTURE_PREVIEW;
  changePasswordFormState$: Observable<ChangePasswordFormState>;
  @Input() updateAction: any;
  @Input() changePasswordAction: any;
  @Input() openChangePasswordFormAction: any;
  @Input() closeChangePasswordFormAction: any;
  @Input() getChangePasswordFormStateSelector: any
  @Input() debounceTime: number;
  @Input() authenticatedUser: AuthenticatedUser;

  constructor(
    private userManagementStore: Store<IDecisionFirstState>,
    private external: ExternalService
  ) {
    super(userManagementStore);
    this.formGroup = this.getUserForm();
    this.updateAction = this.updateAction;
    this.debounceTime = this.debounceTime;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.external.getExternalConfiguration()['identityManagement'] == "AD") {
      this.changePasswordFormState$ = null
    } else {
      this.subscribeOpenChangePasswordForm();
    }
  }

  subscribeOpenChangePasswordForm(): void {
    this.changePasswordFormState$ = this.userManagementStore.select(this.getChangePasswordFormStateSelector)
      .pipe(
        untilDestroyed(this),
        map((value: ChangePasswordFormState) => {
          return value;
        }),
      );
  }

  getUserForm(): FormGroup {
    return new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])),
      picture: new FormControl(''),
    });
  }

  getPictureToPreview(): string {
    let img = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='%23000' d='M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2M8.5 9.5a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0m9.758 7.484A7.985 7.985 0 0 1 12 20a7.985 7.985 0 0 1-6.258-3.016C7.363 15.821 9.575 15 12 15s4.637.821 6.258 1.984'/%3E%3C/g%3E%3C/svg%3E";
    if (this.formGroup.controls.picture.value === '' || this.formGroup.controls.picture.value === null || this.formGroup.controls.picture.value === undefined) {
      img = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='%23000' d='M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2M8.5 9.5a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0m9.758 7.484A7.985 7.985 0 0 1 12 20a7.985 7.985 0 0 1-6.258-3.016C7.363 15.821 9.575 15 12 15s4.637.821 6.258 1.984'/%3E%3C/g%3E%3C/svg%3E";
    } else {
      img = this.formGroup.controls.picture.value;
    }
    return img;
  }

  isAdmin(): boolean {
    return this.authenticatedUser && (this.authenticatedUser.userType === UserType.ADMIN || this.authenticatedUser.userType === UserType.TRIAL);
  }

  isCurrentUser(): boolean {
    return this.editObject && this.authenticatedUser.userId === this.editObject.id;
  }

  onPasswordChanged(value: ChangePasswordRequest): void {
    this.userManagementStore.dispatch(new this.changePasswordAction(value));
  }

  onOpenForm(): void {
    this.userManagementStore.dispatch(new this.openChangePasswordFormAction());
  }

  onCloseForm(): void {
    this.userManagementStore.dispatch(new this.closeChangePasswordFormAction());
  }

  ngOnDestroy() {
    this.userManagementStore.dispatch(new this.closeChangePasswordFormAction());
  }
}
